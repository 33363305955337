<template>
  <div class="box">
    <aside class="menu">
      <div>
        <span class="menu-label">Club Setup</span>
        <ul class="menu-list">
          <li v-if="currentUserHasFullAccess">
            <router-link :disabled="true" to="/clubs" tag="a" active-class="is-active">
              Clubs
            </router-link>
          </li>
          <li>
            <router-link to="/players" tag="a" active-class="is-active">
              Players
            </router-link>
          </li>
          <li v-if="currentUserHasFullAccess">
            <router-link to="/club_usage_summary" tag="a" active-class="is-active">
            Club Usage Summary
            </router-link>
          </li>
          <li v-if="currentUserHasFullAccess">
            <router-link to="/season_status" tag="a" active-class="is-active">
            Season Status
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <span class="menu-label">Accounts</span>
        <ul class="menu-list">
          <li>
            <router-link to="/users" tag="a" active-class="is-active">
              Users
            </router-link>
          </li>
          <li>
            <router-link to="/pandadoc_accounts" tag="a" active-class="is-active">
              PandaDoc Accounts
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="currentUserHasFullAccess">
        <span class="menu-label">Administration</span>
        <ul class="menu-list">
          <li>
            <router-link to="/admin-users" tag="a" active-class="is-active">
              Admin Users
            </router-link>
          </li>
          <li>
            <router-link to="/emails" tag="a" active-class="is-active">
              Emails
            </router-link>
          </li>
          <li>
            <router-link to="/firebase-users" tag="a" active-class="is-active">
              Firebase User Lookup
            </router-link>
          </li>
          <li>
            <router-link to="/push_notifications" tag="a" active-class="is-active">
              Push Notifications
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="currentUserHasFullAccess">
        <span class="menu-label">Advanced</span>
        <ul class="menu-list">
          <li>
            <router-link to="/club_exports" tag="a" active-class="is-active">
              Club Exports
            </router-link>
          </li>
          <li>
            <router-link to="/memcache" tag="a" active-class="is-active">
              Memcache
            </router-link>
          </li>
          <li>
            <router-link to="/pubsub" tag="a" active-class="is-active">
              PubSub
            </router-link>
          </li>
          <li>
            <router-link to="/wepay_notification_prefs" tag="a" active-class="is-active">
              Wepay Notification Prefs
            </router-link>
          </li>
          <li>
            <router-link to="/system_notification" tag="a" active-class="is-active">
              System Notification
            </router-link>
          </li>
          <li>
            <router-link to="/test_page" tag="a" active-class="is-active">
              Test Page
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  computed: {
    currentUserHasFullAccess() {
      return this.$store.getters.user.is_admin;
    },
  },
};
</script>

<style scoped>
  .menu-list {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
  }
</style>
